import React, { useEffect } from 'react';
import clx from 'classnames';

import { DrillDownProvider } from './DrillDownContext/DrillDownContext';
import useOnNavigate from '../../../hooks/useOnNavigate';

import DrillDownMask from './DrillDownMask/DrillDownMask';
import DrillDownHeader from './DrillDownHeader/DrillDownHeader';
import DrillDownPanel from './DrillDownPanel/DrillDownPanel';

import styles from './DrillDown.module.css';

type DrillDownHeaderProps = React.ComponentProps<typeof DrillDownHeader>;
type DrillDownMaskProps = React.ComponentProps<typeof DrillDownMask>;
type DrillDownPanelProps = Omit<React.ComponentProps<typeof DrillDownPanel>, 'isTopLevelMenu' | 'title'>;

interface Props extends DrillDownHeaderProps, DrillDownPanelProps {
    className?: string;
    maskClassName?: DrillDownMaskProps['className'];
    label?: string;
    isOpen?: boolean;
}

const toggleBodyOverflow = (isOpen: boolean) => {
    if (typeof window !== undefined) {
        if (isOpen) {
            document.body.style.setProperty('overflow', 'hidden');
        } else {
            document.body.style.removeProperty('overflow');
        }
    }
}

const DrillDown = ({
    title,
    label,
    className,
    maskClassName,
    onClose,
    isOpen,
    ...rest
}: Props) => {
    const classNames = clx(
        '_js-nav-menu',
        styles.nav,
        isOpen ? styles.navOpen : '',
        className
    );

    useOnNavigate(onClose);

    useEffect(() => {
        toggleBodyOverflow(!!isOpen);
    }, [isOpen]);

    useEffect(() => (
        () => toggleBodyOverflow(false)
    ), []);

    return (
        <DrillDownProvider>
            <DrillDownMask className={maskClassName} isOpen={isOpen} onClose={onClose} />
            <nav className={classNames} aria-label={label}>
                <DrillDownHeader title={title} onClose={onClose} />
                <DrillDownPanel isTopLevelMenu {...rest} />
            </nav>
        </DrillDownProvider>
    );
};

export default DrillDown;
