import { useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';

interface Config {
    disabled?: boolean;
}

const getScrollDirection = (startingScrollPosition: number, newScrollPosition: number) => {
    if (typeof window === 'undefined') {
        return 'static';
    }

    if (startingScrollPosition > newScrollPosition) {
        return 'up';
    }

    if (startingScrollPosition < newScrollPosition) {
        return 'down';
    }

    return 'static';
}

export type OnScrollCallback = (direction: ReturnType<typeof getScrollDirection>) => void;

const useOnScroll = (callback: OnScrollCallback, config: Config = {}) => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
        if (typeof window === 'undefined' || config.disabled) {
            return () => { };
        }

        const handleScroll = throttle(() => {
            const currentScrollPosition = window.scrollY;

            timeout.current = setTimeout(() => {
                const direction = getScrollDirection(currentScrollPosition, window.scrollY);

                callback(direction);

                clearTimeout(timeout.current);
            }, 150);
        }, 200);

        document.addEventListener('scroll', handleScroll);

        return () => {
            handleScroll.cancel();
            document.removeEventListener('scroll', handleScroll);
            clearTimeout(timeout.current);
        }
    }, [callback, config.disabled]);
};

export default useOnScroll;
