import { useCallback, useRef, useState } from 'react';

import useWindowSizeCheck from '../../../../../hooks/useWindowSizeCheck';
import useOnScroll, { OnScrollCallback } from '../../../../../hooks/useOnScroll';

const isHeaderInView = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    const windowScrollPosition = window.scrollY;
    const mainHeaderHeight = document.querySelector('header[data-site-header=true]')?.clientHeight || 80;

    return windowScrollPosition < mainHeaderHeight;
}

const useStickyHeader = () => {
    const hasScrolled = useRef(false);

    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const [isDesktop] = useWindowSizeCheck(1023);

    const handleScroll = useCallback((direction) => {
        setIsHeaderSticky((previousValue) => {
            if (isHeaderInView()) {
                return false;
            }

            switch (direction) {
                case 'down':
                    return false;
                case 'up':
                    return true;
                case 'static':
                default:
                    return previousValue;
            }
        });

        hasScrolled.current = true;
    }, []) as OnScrollCallback;

    useOnScroll(handleScroll, {
        disabled: isDesktop
    });

    return isDesktop ? false : isHeaderSticky;
};

export default useStickyHeader;
