import React, { useState } from 'react';

import usePatientReorders from './hooks/usePatientReorders';
import formatQuantityLabel from '../../../../../Treatment/helpers/formatQuantityLabel';
import formatPrice from '../../../../../../helpers/formatPrice';
import useStartConsultation from '../../../../../Consultation/hooks/useStartConsultation';
import { PreviousOrder } from '../../../../../../types/api/patient/PreviousOrder';
import sendSentryMessage from '../../../../../../lib/sentry/helpers/sendSentryMessage';

import LoadingIcon from '../../../../../_ui/_blocks/Icons/LoadingIcon/LoadingIcon';
import Link from '../../../../../NextJs/Link/Link';
import ButtonLink from '../../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import ButtonBusy from '../../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';

const reordersUrl = '/account/orders/treatment-reorders';
const displayLimit = 3;

const ReorderList = () => {
    const [titrationClicked, setTitrationClicked] = useState<number | null>(null);
    const { reorders, isLoading, isValidating } = usePatientReorders();
    const startConsultation = useStartConsultation();

    const isRequestLoading = isLoading || isValidating;

    const handleTitrationClick = async (reorder: PreviousOrder) => {
        setTitrationClicked(reorder.reference);
        const conditionSlug = !Array.isArray(reorder.public) ? reorder.public.condition.slug : '';

        if (!conditionSlug) {
            sendSentryMessage(
                `Condition slug doesn't exist on reorder item in account drill down menu.`,
                (scope) => {
                    scope.setExtra('Treatment name:', reorder.treatment);
                    scope.setExtra('Treatment type:', reorder.condition);
                    scope.setExtra('Treatment reference:', reorder.reference);
                    scope.setExtra('Treatment condition:', reorder.condition);
                    scope.setExtra('Treatment quantity:', reorder.quantity);
                    scope.setExtra(
                        'Condition slug from public cache:',
                        `${conditionSlug || 'Not available'} (${typeof conditionSlug})`
                    );
                }
            );

            setTitrationClicked(null);
            return;
        }

        await startConsultation(conditionSlug, {
            conditionId: reorder.condition_id,
            treatmentId: reorder.reference,
            conditionName: reorder.condition,
            quantity: reorder.quantity,
        });
    }

    return (
        <div className="px-100 pb-100 space-y-050">
            {isRequestLoading ? (
                <LoadingIcon className="mx-auto" />
            ) : (
                <ul className="space-y-050">
                    {reorders.slice(0, displayLimit).map((reorder) => {
                        const isTitration = reorder?.configuration?.titrations?.enabled;
                        const handleClick = () => handleTitrationClick(reorder);

                        return (
                            <li
                                key={reorder.reference}
                                className="flex gap-050 items-start justify-between p-075 rounded-100 border border-decorators-subtle"
                            >
                                <div>
                                    <Typography as="div" typeset="heading" size="080">
                                        {reorder.treatment}
                                    </Typography>
                                    <div className="flex gap-100">
                                        <Typography typeset="paragraph" size="070">
                                            {formatQuantityLabel(reorder)}
                                        </Typography>
                                        <Typography typeset="emphasis" size="070">
                                            {formatPrice(reorder.total)}
                                        </Typography>
                                    </div>
                                </div>
                                {isTitration ? (
                                    <ButtonBusy
                                        size="micro"
                                        className="flex-shrink-0"
                                        onClick={handleClick}
                                        disabled={!!titrationClicked && titrationClicked !== reorder.reference}
                                        loading={!!titrationClicked && titrationClicked === reorder.reference}
                                    >
                                        Reorder
                                    </ButtonBusy>
                                ) : (
                                    <ButtonLink
                                        size="micro"
                                        href={`${reordersUrl}?reorder=${reorder.reference}`}
                                        className="flex-shrink-0"
                                        disabled={!!titrationClicked}
                                    >
                                        Reorder
                                    </ButtonLink>
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
            <Link
                href={reordersUrl}
                className="block p-075 rounded-100 border border-decorators-subtle text-center type-090 type-link"
            >
                {reorders.length <= displayLimit ? 'View all' : `View ${reorders.length - displayLimit} more`}
            </Link>
        </div>
    );
};

export default ReorderList;
