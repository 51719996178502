import React from 'react';

import { HeaderFields } from '../../../../Header/Header';
import useShouldApplyDesktopStyles from '../../../../MainNavigation/hooks/useShouldApplyDesktopStyles';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Link from '../../../../../NextJs/Link/Link';
import NavigationLink from '../../../../NavigationLink/NavigationLink';
import Panel from '../../Panel/Panel';

import styles from './GenericMegaMenu.module.css'

type PanelProps = Omit<React.ComponentProps<typeof Panel>, 'children'>;

interface Props extends PanelProps {
    navItems: NonNullable<NonNullable<HeaderFields['mainNavigation']>['items'][number]['children']>
    treatmentCount: number;
    conditionCount: number;
}

const GenericMegaMenu = ({
    title,
    navItems,
    calloutButtonText,
    calloutButtonHref,
    calloutButtonVariant,
    treatmentCount,
    conditionCount,
}: Props) => {
    const shouldApplyDesktopStyles = useShouldApplyDesktopStyles();

    return (
        <Panel
            title={title}
            calloutButtonText={calloutButtonText}
            calloutButtonHref={calloutButtonHref}
            calloutButtonVariant={calloutButtonVariant}
            className={shouldApplyDesktopStyles ? styles.subMenu : undefined}
        >
            <div className={shouldApplyDesktopStyles ? 'lg:p-200' : undefined}>
                {shouldApplyDesktopStyles ? (
                    <div className="hidden lg:flex justify-between items-center type-subtitle leading-200">
                        {title}
                        {calloutButtonHref ? (
                            <Link
                                href={calloutButtonHref}
                                title={`View all ${title}`}
                                className="p-050 -m-050 type-paragraph type-link-reverse"
                            >
                                View all
                            </Link>
                        ) : null}
                    </div>
                ) : null}
                <ul className={shouldApplyDesktopStyles ? styles.list : undefined}>
                    {navItems.map((navItem) => (
                        <li key={navItem._key}>
                            <NavigationLink
                                title={`See all ${navItem.title} treatments`}
                                navItem={navItem}
                                className={`block type-emphasis type-100 p-100 hover:text-actions-secondary ${shouldApplyDesktopStyles ? 'lg:p-0' : ''}`}
                            >
                                {navItem.title}
                            </NavigationLink>
                        </li>
                    ))}
                </ul>
            </div>
            {shouldApplyDesktopStyles ? (
                <div className={styles.callout}>
                    <Typography as="div" typeset="heading" size="100">
                        We offer {treatmentCount} over-the-counter and prescription
                        <br /> medicines, for the treatment of {conditionCount} conditions.
                    </Typography>
                    <Typography as="div" color="quiet" size="100" className="mt-050">
                        We also provide expert advice on a range of common health issues.
                    </Typography>
                    <ButtonLink
                        variant="primary"
                        href="/treatments"
                        title="Find your treatment"
                        className="mt-100 text-center mx-auto"
                    >
                        Find your treatment
                    </ButtonLink>
                </div>
            ) : null}
        </Panel>
    );
};

export default GenericMegaMenu;
