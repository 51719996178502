import { useRef, useCallback, useEffect } from 'react';

import useOnNavigate from '../../../../hooks/useOnNavigate';

/**
 * Adds a class to the body to pointer event none the main nave menu and reset document focus to close the
 * mega menu when navigating between pages.
 */
const useCloseMegaMenuOnNavigation = () => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    const closeMegaMenuOnNavigation = useCallback(() => {
        // Reset document focus.
        (document.activeElement as HTMLElement | null)?.blur();

        // Clear any current timeout.
        clearTimeout(timeout.current);

        // Add class to the body to disable pointer events.
        document.body.classList.add('_js-close-megamenu');

        // Remove the class after a short delay.
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-close-megamenu');
        }, 500);
    }, []);

    useOnNavigate(closeMegaMenuOnNavigation);

    // On component unmount, clear the timeout.
    useEffect(() => clearTimeout(timeout.current), []);
};

export default useCloseMegaMenuOnNavigation;
