import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

/**
 * Gets the size of the window.
 */
const useGetWindowSize = () => {
    const [windowSize, setWindowSize] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        // Throttle the handle resize function so it's not called every millisecond.
        const handleResize = throttle(() => {
            setWindowSize(window.innerWidth);
        }, 500);

        window.addEventListener('resize', handleResize);

        /** on unmount clean up the timeout and the listener */
        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};

export default useGetWindowSize;
