import { useMemo } from 'react';
import useSWR from 'swr';

import patientsService from '../../../../../../../data/services/patients.service';
import { useAuthContext } from '../../../../../../../data/context/authContext';
import { PreviousOrder } from '../../../../../../../types/api/patient/PreviousOrder';

const usePatientReorders = () => {
    const { patientUuid } = useAuthContext();

    const { data, isLoading, isValidating } = useSWR(
        'patient-previous-treatments',
        () => (
            patientUuid ? patientsService.getPreviousTreatments(patientUuid) : null
        ),
        {
            revalidateIfStale: true,
            revalidateOnMount: true,
            revalidateOnFocus: false,
            revalidateInterval: 600000, // Every 10 minutes.
            refreshWhenHidden: true,
            shouldRetryOnError: false,
            dedupingInterval: 5000 // Stop duplicate requests for 5 seconds after a fresh request is made.
        }
    );

    const reorders = useMemo(() => (
        data?.data?.data || []
    ), [data]);

    return {
        reorders: reorders as Array<PreviousOrder>,
        isLoading,
        isValidating,
    }
};

export default usePatientReorders;
