'use client';

import React from 'react';
import { Transition } from '@headlessui/react';
import Image from 'next/image';

import useStickyHeader from './hooks/useStickyHeader';

import Link from '../../../NextJs/Link/Link';
import SearchInput from '../../../Search/SearchInput/SearchInput';
import SecureCheckoutNotice from '../SecureCheckoutNotice/SecureCheckoutNotice';
import AccountButton from '../AccountButton/AccountButton';
import BasketButton from '../BasketButton/BasketButton';
import MainMenuButton from '../MainMenuButton/MainMenuButton';

import styles from './StickyHeader.module.css';

interface Props {
    disableNavigation?: boolean;
    disableSearch?: boolean;
    disableAccount?: boolean;
    disableBasket?: boolean;
    enableSecureCheckout?: boolean;
}

const StickyHeader = ({
    disableNavigation,
    disableAccount,
    disableBasket,
    disableSearch,
    enableSecureCheckout
}: Props) => {
    const shouldRenderLinks = !disableNavigation || !disableAccount || !disableBasket || !disableSearch || enableSecureCheckout;

    const isSticky = useStickyHeader();

    return (
        <Transition
            as="div"
            appear
            show={isSticky}
            className={styles.header}
            enter="transition-transform duration-default"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition-transform duration-default"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
            aria-hidden="true"
        >
            <Link
                href="/"
                title="The Independent Pharmacy homepage"
                aria-label="The Independent Pharmacy homepage"
                className={styles.logo}
            >
                <Image
                    src="/images/the-independent-pharmacy-logo.svg"
                    alt="The Independent Pharmacy"
                    width={100}
                    height={38}
                    loading="eager"
                />
            </Link>
            {shouldRenderLinks ? (
                <div className="flex ml-auto">
                    {enableSecureCheckout ? <SecureCheckoutNotice /> : null}
                    {!disableAccount ? <AccountButton /> : null}
                    {!disableBasket ? <BasketButton /> : null}
                    {!disableNavigation ? <MainMenuButton /> : null}
                </div>
            ) : null}
            {!disableSearch ? (
                <SearchInput
                    name="header-search"
                    placeholder="Search..."
                    invokesOverlay
                    className="w-full px-075 pt-050 pb-075"
                />
            ) : null}
        </Transition>
    );
};

export default StickyHeader;
