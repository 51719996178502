'use client';

import { createContext, Dispatch, SetStateAction, useState, useContext } from 'react';

interface Props {
    children: React.ReactNode;
}

type DrillDownStack = Array<string>;

const DrillDownStackContext = createContext<DrillDownStack>([]);
const UpdateDrillDownStackContext = createContext<Dispatch<SetStateAction<DrillDownStack>>>(() => {});

export const DrillDownProvider = ({ children }: Props) => {
    const [drillDownStack, setDrillDownStack] = useState<DrillDownStack>([]);

    return (
        <DrillDownStackContext.Provider value={drillDownStack}>
            <UpdateDrillDownStackContext.Provider value={setDrillDownStack}>
                {children}
            </UpdateDrillDownStackContext.Provider>
        </DrillDownStackContext.Provider>
    );
};

export const useDrillDownStackContext = () => {
    const drillDownStack = useContext(DrillDownStackContext);
    if (drillDownStack === undefined) {
        throw new Error('useDrillDownStackContext must be used within a DrillDownProvider.');
    }

    return drillDownStack;
};

export const useModifyDrillDownStackContext = () => {
    const setDrillDownStack = useContext(UpdateDrillDownStackContext);
    if (setDrillDownStack === undefined) {
        throw new Error('useModifyDrillDownStackContext must be used within a DrillDownProvider.');
    }

    return {
        addToStack: (entry: string) => {
            setDrillDownStack((stack) => {
                if (stack.includes(entry)) {
                    return stack.filter((item) => item !== entry);
                }

                return [
                    ...stack,
                    entry,
                ]
            })
        },
        clearStack: () => {
            setDrillDownStack([]);
        },
        popStack: () => {
            setDrillDownStack((stack) => (
                stack.slice(0, -1)
            ));
        }
    };
};
