import React from 'react';
import { usePathname } from 'next/navigation';

import { NavigationType } from '../../Header/Header';
import { useDrillDownStackContext } from '../DrillDownContext/DrillDownContext';
import { doesNavItemHaveDropdown, getTypeOfMegamenuFromClasses } from './helpers/drillDownHelpers';

import TreatmentsMegaMenu from './CustomMenu/TreatmentsMegaMenu/TreatmentsMegaMenu';
import ConditionsMegaMenu from './CustomMenu/ConditionsMegaMenu/ConditionsMegaMenu';
import BrandsMegaMenu from './CustomMenu/BrandsMegaMenu/BrandsMegaMenu';
import DrillDownLink from './DrillDownLink/DrillDownLink';
import Panel from './Panel/Panel';

import styles from './DrillDownPanel.module.css';
import ReorderList from './CustomMenu/ReorderList/ReorderList';

type PanelProps = Omit<React.ComponentProps<typeof Panel>, 'children' | 'className'>;

interface Props extends PanelProps {
    navItems: NavigationType['items'];
    treatmentCount?: number;
    conditionCount?: number;
    isMainMenu?: boolean;
    'data-dd-dropdown'?: boolean;
    children?: React.ReactNode;
}

const customSubMenus = {
    treatments: TreatmentsMegaMenu,
    conditions: ConditionsMegaMenu,
    brands: BrandsMegaMenu,
};

const DrillDownPanel = ({
    navItems,
    title,
    treatmentCount,
    conditionCount,
    isTopLevelMenu,
    isMainMenu,
    children,
    ...rest
}: Props) => {
    const pathName = usePathname();
    const drillDownStack = useDrillDownStackContext();

    return (
        <Panel title={title} isTopLevelMenu={isTopLevelMenu} {...rest}>
            <ul className={`${styles.list} ${isTopLevelMenu ? styles.listItemPaddingSmall : styles.listItemPaddingLarge}`}>
                {navItems.map((navItem) => {
                    const isDemoted = navItem.classes?.includes('demoted') || undefined;

                    const hasSubMenu = doesNavItemHaveDropdown(pathName || '', navItem);

                    const CustomMenu = hasSubMenu
                        ? customSubMenus[getTypeOfMegamenuFromClasses(navItem.classes) as keyof typeof customSubMenus]
                        : null;

                    const showReorder = navItem?.classes?.includes('reorders');

                    // Only show this if we have children and no mega-menu component is found.
                    const isDropDown = hasSubMenu && !CustomMenu && !showReorder;

                    return (
                        <li
                            key={navItem._key}
                            className={drillDownStack.includes(navItem._key) ? styles.subMenuOpen : undefined}
                            data-demoted={isDemoted}
                        >
                            <DrillDownLink
                                navItem={navItem}
                                isTopLevelMenu={isTopLevelMenu}
                                alwaysRenderLink={isMainMenu}
                            />
                            {showReorder ? (
                                <ReorderList />
                            ) : null}
                            {CustomMenu ? (
                                <CustomMenu
                                    navItems={navItem.children || []}
                                    treatmentCount={treatmentCount || 1}
                                    conditionCount={conditionCount || 1}
                                />
                            ) : null}
                            {isDropDown ? (
                                <DrillDownPanel
                                    data-dd-dropdown
                                    title={navItem.title}
                                    treatmentCount={treatmentCount}
                                    conditionCount={conditionCount}
                                    navItems={navItem.children || []}
                                    isMainMenu={isMainMenu}
                                />
                            ) : null}
                        </li>
                    );
                })}
                {children}
            </ul>
        </Panel>
    );
};

export default DrillDownPanel;
