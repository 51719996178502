import React, { Fragment } from 'react';
import { usePathname } from 'next/navigation';
import clx from 'classnames';

import { NavigationType } from '../../../Header/Header';
import { doesNavItemHaveDropdown } from '../helpers/drillDownHelpers';
import { useModifyDrillDownStackContext } from '../../DrillDownContext/DrillDownContext';

import Icon from '../../../../_ui/_blocks/Icon/Icon';
import NavigationLink from '../../../NavigationLink/NavigationLink';

interface Props {
    navItem: NavigationType['items'][number];
    alwaysRenderLink?: boolean;
    isTopLevelMenu?: boolean;
}

const DrillDownLink = ({ navItem, isTopLevelMenu, alwaysRenderLink }: Props) => {
    const pathName = usePathname();
    const { addToStack } = useModifyDrillDownStackContext();

    const hasSubMenu = doesNavItemHaveDropdown(pathName || '', navItem);

    const isDemoted = navItem.classes?.includes('demoted') || undefined;
    const linkClassNames = clx(
        isTopLevelMenu ? 'py-075' : 'py-100',
        isDemoted ? '!type-paragraph !type-090' : '',
    );

    const handleDrillDownClick = () => {
        addToStack(navItem._key);
    }

    return (
        <Fragment>
            {hasSubMenu ? (
                <button
                    type="button"
                    aria-hidden="true"
                    onClick={handleDrillDownClick}
                    className={linkClassNames}
                >
                    {navItem.title}
                    <Icon
                        icon="chevron-right-light"
                        size="xsmall"
                        alt=""
                        aria-hidden="true"
                        loading="eager"
                    />
                </button>
            ) : null}
            {alwaysRenderLink || !hasSubMenu ? (
                <NavigationLink
                    navItem={navItem}
                    className={linkClassNames}
                    partiallyActive
                >
                    {navItem.title}
                    <Icon
                        icon="chevron-down-light"
                        size="xxsmall"
                        alt=""
                        aria-hidden="true"
                        loading="eager"
                        className={`hidden lg:block ${!hasSubMenu ? 'invisible' : ''}`}
                    />
                </NavigationLink>
            ) : null}
        </Fragment>
    );
};

export default DrillDownLink;
