import { NavigationType } from '../../../Header/Header';

type NavItem = NavigationType['items'][number];

export const getTypeOfMegamenuFromClasses = (classes: NavItem['classes']) => {
    if (!classes) {
        return '';
    }

    const megaMenuClass = classes.split(' ').find((className: string) => (
        className.includes('_has-megamenu--')
    )) || '';

    return megaMenuClass.replace('_has-megamenu--', '') as 'treatments' | 'conditions' | 'brands';
};

export const doesNavItemHaveDropdown = (pathName: string, navItem: NavItem) => {
    if (!navItem.children?.length) {
        return false;
    }

    const type = getTypeOfMegamenuFromClasses(navItem.classes || '');

    // If it's the brands menu item and we're on a GPPC page.
    if (type === 'brands' && pathName.includes('/gppc')) {
        return false;
    }

    // If it's the treatments menu item and we're on the homepage or GPPC page.
    if (type === 'treatments' && (pathName === '/' || pathName.includes('/gppc'))) {
        return false;
    }

    return true;
}
