'use client';

import React from 'react';

import { HeaderFields } from '../../../../Header/Header';

import GenericMegaMenu from '../GenericMegaMenu/GenericMegaMenu';

interface Props {
    navItems: NonNullable<NonNullable<HeaderFields['mainNavigation']>['items'][number]['children']>;
    treatmentCount: number;
    conditionCount: number;
}

/**
 * Megamenu to display all the brand links.
 */
const BrandsMegaMenu = ({ navItems, treatmentCount, conditionCount }: Props) => (
    <GenericMegaMenu
        title="Popular Brands"
        navItems={navItems}
        calloutButtonText="View All Brands"
        calloutButtonHref="/brands"
        calloutButtonVariant="secondary"
        treatmentCount={treatmentCount}
        conditionCount={conditionCount}
    />
);

export default BrandsMegaMenu;
