'use client';

import React, { useRef, useMemo } from 'react';
import clx from 'classnames';
import { PortableText, type PortableTextComponents } from 'next-sanity';

import { Promotions } from '../../../../lib/sanity/queries/sitewideContent.query';
import useGetWindowSize from '../../../../hooks/useGetWindowSize';
import { isEven } from '../../../../helpers/maths';
import useResetAnimation from './hooks/useResetAnimation';

import styles from './PromotionsBanner.module.css';

interface Props {
    promotions: Promotions;
}

const components: PortableTextComponents = {
    block: {
        normal: ({ children }) => children,
    },
};

export const PromotionsBannerSsr = () => (
    <div className={`${styles.banner} h-[2.25rem]`} data-site-banner="true" />
);

/**
 * Animated banner for the site.
 */
const PromotionsBanner = ({ promotions = [] }: Props) => {
    const windowSize = useGetWindowSize();
    const currentRow = useRef(0);

    const handleAnimationReset = useResetAnimation('[data-site-banner=true] li');

    // Works out if the animation should run based on the number of promotions to display and which screen is
    // being viewed.
    const shouldAnimate = useMemo(() => {
        if (windowSize < 769 && promotions.length > 1) {
            return true;
        }

        if (windowSize < 1025 && promotions.length > 2) {
            return true;
        }

        return promotions.length > 3;
    }, [promotions.length, windowSize]);

    const classNames = clx(
        styles.list,
        shouldAnimate ? styles.animate : '',
    );

    const getItemClasses = (index: number) => {
        // If the screen is mobile, no classes are needed.
        if (windowSize < 769) {
            return '';
        }

        // If the screen is tablet.
        if (windowSize < 1025) { // Tablet
            return clx(
                // If it's the starting item of the row.
                isEven(index) ? `${styles.tabletGrid} ${styles.noBefore}` : '',
                // If its the last item and it's not a pair, then we want the message to span all columns.
                (isEven(index) && index === (promotions.length - 1)) ? styles.tabletGridSpanFull : '',
            )
        }

        // If the screen is desktop.
        const remainder = promotions.length % 3;

        // Work out how many items are remaining. If there is one remainder and this is the last item, we want
        // the message to span all columns.
        if (remainder === 1 && index === (promotions.length - 1)) {
            return `${styles.desktopGridSpanFull} ${styles.noBefore}`;
        }

        // Work out how many items are remaining. If there are 2 items remaining and this is the 2nd to last
        // item, we want both this message and the next message to span half half. The next message is handled
        // by the CSS class applied.
        if (remainder === 2 && index === (promotions.length - 2)) {
            return `${styles.desktopGridSpanHalf} ${styles.noBefore}`;
        }

        // If the message is the start of the row, apply this class to handle the positioning of the messages
        // in the row.
        return index % 3 === 0 ? `${styles.desktopGrid} ${styles.noBefore}` : '';
    }

    // Logic to keep track of which row we're on.
    const updateCurrentRow = (index: number) => {
        // If the index is 0, then track the rows from the beginning.
        if (index < 1) {
            currentRow.current = 0;
        }

        // On mobile, the row is always its index as only one item is shown at a time.
        if (windowSize < 769) {
            currentRow.current = index;
            return;
        }

        // On tablet, if the index is even and isn't the first row, then its the start of the row so increment
        // by one.
        if (windowSize < 1025) {
            currentRow.current = index && isEven(index) ? currentRow.current + 1 : currentRow.current;
            return;
        }

        // On desktop, if the index is divisible by 3 and isn't the first row, then its the start of the row
        // so increment by one.
        currentRow.current = index && index % 3 === 0 ? currentRow.current + 1 : currentRow.current
    }

    return (
        <div className={styles.banner}>
            <ul className={classNames} data-site-banner="true">
                {promotions.map((promotion, index) => {
                    updateCurrentRow(index);
                    const isLastItem = index === (promotions.length - 1);

                    return (
                        <li
                            key={promotion._key}
                            style={{ animationDelay: `${currentRow.current * 2.5}s` }}
                            onAnimationEnd={isLastItem ? handleAnimationReset : undefined}
                            className={getItemClasses(index)}
                        >
                            <PortableText components={components} value={promotion.content} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default PromotionsBanner;
