import React, { useRef, useEffect } from 'react';
import clx from 'classnames';

import styles from './DrillDownMask.module.css';

interface Props {
    isOpen?: boolean;
    onClose: () => void;
    className?: string;
}

const DrillDownMask = ({ isOpen, onClose, className = '' }: Props) => {
    // This stops the animation from running on initial page load.
    const enableAnimation = useRef(false);

    let maskAnimationClassName = enableAnimation.current ? styles.maskClosed : '';
    if (isOpen) {
        maskAnimationClassName = styles.maskOpen;
    }

    const classNames = clx(
        styles.mask,
        maskAnimationClassName,
        className,
    );

    useEffect(() => {
        if (enableAnimation.current) {
            return;
        }

        enableAnimation.current = !!isOpen;
    }, [isOpen]);

    return <button type="button" onClick={onClose} className={classNames} aria-hidden="true" />;
};

export default DrillDownMask;
