'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import clx from 'classnames';

import Link from './NextJs/Link/Link';

// Props interface extends from our custom NextJS link props.
interface LinkActiveClassNameProps extends React.ComponentProps<typeof Link> {
    activeClassName?: string;
    partiallyActive?: boolean;
    matchesPath?: string;
}

const LinkActiveClassName = ({ partiallyActive, matchesPath, activeClassName, ...props}: LinkActiveClassNameProps) => {
    const currentPath = usePathname();
    const href = (props.href as string);

    const isActive = (partiallyActive && currentPath?.includes(href)) || (matchesPath && currentPath?.includes(matchesPath)) || undefined;

    const classNames = clx(props.className,
        currentPath === href ? activeClassName: null,
        isActive ? activeClassName : null,
    );

    return (<Link {...props} data-active={isActive} className={classNames}/>);
};

export default LinkActiveClassName;
