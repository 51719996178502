'use client';

import React from 'react';

import { HeaderFields } from '../../../../Header/Header';
import useShouldApplyDesktopStyles from '../../../../MainNavigation/hooks/useShouldApplyDesktopStyles';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import NavigationLink from '../../../../NavigationLink/NavigationLink';
import Panel from '../../Panel/Panel';

import styles from './TreatmentsMegaMenu.module.css';

interface Props {
    navItems: NonNullable<NonNullable<HeaderFields['mainNavigation']>['items'][number]['children']>;
}

const navTitle = 'Popular Treatments';

const TreatmentsMegaMenu = ({ navItems }: Props) => {
    const shouldApplyDesktopStyles = useShouldApplyDesktopStyles();

    return (
        <Panel
            title={navTitle}
            calloutButtonText="View All Treatments"
            calloutButtonHref="/treatments"
            calloutButtonVariant="secondary"
            className={shouldApplyDesktopStyles ? styles.subMenu : undefined}
        >
            {shouldApplyDesktopStyles ? (
                <div className={styles.header}>
                    <Typography as="div" typeset="subtitle">{navTitle}</Typography>
                    <div className="hidden gap-100 lg:flex">
                        <ButtonLink variant="primary" href="/treatments">
                            View all treatments
                        </ButtonLink>
                        <ButtonLink variant="secondary" href="/conditions">
                            View all conditions
                        </ButtonLink>
                    </div>
                </div>
            ) : null}
            <ul className={shouldApplyDesktopStyles ? styles.list : undefined}>
                {navItems?.map((navItem) => {
                    const title = `See all ${navItem.title} treatments`;

                    return (
                        <li
                            key={navItem._key}
                            className={`space-y-050 p-100 ${shouldApplyDesktopStyles ? 'lg:p-0' : ''}`}
                        >
                            <NavigationLink
                                title={title}
                                navItem={navItem}
                                className="type-emphasis type-100 block"
                                prefetch="hover"
                            >
                                {navItem.title}
                            </NavigationLink>
                            {navItem.children ? (
                                <ul className="space-y-050">
                                    {navItem.children.map((child) => (
                                        <Typography
                                            as="li"
                                            key={child._key}
                                            className="hover:text-actions-secondary"
                                            size="090"
                                        >
                                            <NavigationLink
                                                title={`View ${child.title}`}
                                                navItem={child}
                                                prefetch="hover"
                                                className="block"
                                            >
                                                {child.title}
                                            </NavigationLink>
                                        </Typography>
                                    ))}
                                </ul>
                            ) : null}
                            <NavigationLink
                                title={title}
                                navItem={navItem}
                                className="inline-block type-link-reverse"
                            >
                                View all
                            </NavigationLink>
                        </li>
                    );
                })}
            </ul>
        </Panel>
    );
};

export default TreatmentsMegaMenu;
