'use client';

import { createContext, Dispatch, SetStateAction, useState, useContext } from 'react';

interface Props {
    children: React.ReactNode;
}

const IsMainMenuOpenContext = createContext(false);
const SetIsMainMenuOpenContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {});

const IsAccountMenuOpenContext = createContext(false);
const SetIsAccountMenuOpenContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {});

export const SiteNavigationProvider = ({ children }: Props) => {
    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

    return (
        <SetIsMainMenuOpenContext.Provider value={setIsMainMenuOpen}>
            <SetIsAccountMenuOpenContext.Provider value={setIsAccountMenuOpen}>
                <IsMainMenuOpenContext.Provider value={isMainMenuOpen}>
                    <IsAccountMenuOpenContext.Provider value={isAccountMenuOpen}>
                        {children}
                    </IsAccountMenuOpenContext.Provider>
                </IsMainMenuOpenContext.Provider>
            </SetIsAccountMenuOpenContext.Provider>
        </SetIsMainMenuOpenContext.Provider>
    );
};

export const useIsMainMenuOpenContext = () => {
    const isOpen = useContext(IsMainMenuOpenContext);
    if (isOpen === undefined) {
        throw new Error('useIsMainMenuOpenContext must be used within a SiteNavigationProvider.');
    }

    return isOpen;
};

export const useSetMainMenuOpenContext = () => {
    const setIsOpen = useContext(SetIsMainMenuOpenContext);
    if (setIsOpen === undefined) {
        throw new Error('useSetMainMenuOpenContext must be used within a SiteNavigationProvider.');
    }

    return (isOpen: boolean) => {
        setIsOpen(isOpen)
    };
};

export const useIsAccountMenuOpenContext = () => {
    const isOpen = useContext(IsAccountMenuOpenContext);
    if (isOpen === undefined) {
        throw new Error('useIsAccountMenuOpenContext must be used within a SiteNavigationProvider.');
    }

    return isOpen;
};

export const useSetAccountMenuOpenContext = () => {
    const setIsOpen = useContext(SetIsAccountMenuOpenContext);
    if (setIsOpen === undefined) {
        throw new Error('useSetAccountMenuOpenContext must be used within a SiteNavigationProvider.');
    }

    return (isOpen: boolean) => {
        setIsOpen(isOpen)
    };
};
