import { useRef, useEffect } from 'react';

/**
 * Gets elements using the selector that's passed in and clears the animation on those elements when the
 * return function is called.
 */
const useResetAnimation = (selector: string) => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    // When the animation ends, reset all the animations.
    const handleAnimationEnd = () => {
        if (typeof window === 'undefined') {
            return;
        }

        // Get all the list items from below.
        const items = document.querySelectorAll(selector) as NodeListOf<HTMLLIElement>;

        if (!items.length) {
            return;
        }

        // For each item, add animation name rule to clear the existing animation.
        items.forEach((item) => {
            item.style.setProperty('animation-name', 'none');
        });

        // After a timeout, clear the animation-name above to re-instate the animation.
        timeout.current = setTimeout(() => {
            items.forEach((item) => item.style.removeProperty('animation-name'));
        }, 250);
    }

    // If the component unmounts, clear any remaining timeout.
    useEffect(() => (
        () => {
            clearTimeout(timeout.current);
        }
    ), []);

    return handleAnimationEnd;
};

export default useResetAnimation;
