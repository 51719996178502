import { usePathname, useSelectedLayoutSegment } from 'next/navigation';

const useShouldApplyDesktopStyles = () => {
    const pathName = usePathname();
    const segment = useSelectedLayoutSegment();

    // If it's not the account page and not the consultation page, use the desktop styling.
    const shouldStyleDesktop =
        !pathName?.includes('/account') &&
        !pathName?.startsWith(`/${segment || 'unknown'}/consultation`);

    return shouldStyleDesktop;
};

export default useShouldApplyDesktopStyles;
